@import 'common_constants/styles/colors.scss';

.spin-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &.fill {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .main-cube {
    position: absolute;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;
    gap: 5px;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    transform: translate(-50%, -50%);

    .cube-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: calc(50%);
      height: calc(50%);
      border-radius: 5px;
      .inner-cube {
        width: 60%;
        height: 60%;
        background-color: $blue_6;
        border-radius: 4px;
      }
    }

    .cube1 {
      top: 0;
      left: 0;
      opacity: 0.6;
    }
    .cube2 {
      top: 0;
      left: 50%;
      opacity: 0.8;
    }
    .cube3 {
      top: 50%;
      left: 0;
      opacity: 0.4;
    }
    .cube4 {
      top: 50%;
      left: 50%;
      opacity: 1;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .children-container {
    width: 100%;
    height: 100%;
  }

  .half-transparent-layout {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.601);
  }

  .loading-text {
    margin-top: 32px;
    color: $blue_6;
    animation: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
