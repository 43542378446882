.swipe {
  position: fixed;
  margin-left: -100px;
  margin-top: -15px;
  left: 0%;
  top: 50%;
  z-index: 1000;
}

.swipe .inner {
  background-color: rgba(128, 128, 128, 0.662);
  border-radius: 50%;
  margin-top: 5px;
  height: 40px;
  width: 0px;
}

.swipe.swipe-started .inner {
  animation: swipe 0.3s ease-out forwards, circle-phase-one 0.3s ease-out forwards;
  animation-fill-mode: forwards;
}

.swipe.swipe-finished .inner {
  animation: swipe-finished 0.3s ease-out forwards, circle-phase-two 0.3s ease-out forwards;
}

.swipe.swipe-canceled .inner {
  animation: swipe-canceled 0.3s ease-out forwards, circle-phase-three 0.3s ease-out forwards;
}

.top-arrow,
.bottom-arrow {
  position: absolute;
  width: 13px;
  height: 3px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.643);
}

.top-arrow {
  top: 60%;
  left: 45%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.bottom-arrow {
  top: 40%;
  left: 45%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

@keyframes swipe {
  0% {
    transform: translateX(0px);
    width: 0px;
  }
  100% {
    width: 40px;
    transform: translateX(120px);
  }
}

@keyframes swipe-finished {
  0% {
    width: 40px;
    transform: translateX(120px);
  }
  100% {
    width: 40px;
    transform: translateX(120px);
  }
}

@keyframes swipe-canceled {
  0% {
    width: 40px;
    transform: translateX(120px);
  }
  100% {
    transform: translateX(0px);
    width: 0px;
  }
}

@keyframes circle-phase-one {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes circle-phase-two {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes circle-phase-three {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
