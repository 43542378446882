.swipe-refresh-icon {
    position: fixed;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 56px;
    height: 56px !important;
    z-index: 10;

    div {
        width: 100%;
        height: 100%;
        padding: 8px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: #1677ff;
        --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
        mask: var(--_m);
        -webkit-mask-composite: source-out;
        mask-composite: subtract;
    }
}

.swipe-refresh-icon-rotating {
    animation: swipe-refresh-icon-rotation infinite 0.6s linear;
}

@keyframes swipe-refresh-icon-rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}