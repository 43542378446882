@import '_mixins.scss';
@import 'common_constants/styles/colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#root,
body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  background-color: $gray_3;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  padding-bottom: 40px;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  background-color: $gray_3;
}

.layout {
  height: 100%;

  a {
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  h2 {
    padding-left: 40px;
    margin: 8px 0;
  }
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

// guess: old code block
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #f5f5f5;

  font-family: 'Roboto', 'Segoe UI', Roboto;
  font-size: 14px;

  &.mobDisabled {
    @media (max-width: 768px) {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.ant-layout-content {
  padding-top: 62px;
  height: 100%;
}

.ant-layout-sider .ant-layout-sider-zero-width-trigger {
  background: none;
  top: 0;
}

.ant-layout-sider .ant-layout-sider-zero-width-trigger button {
  color: $gray_13 !important;
}

.default_page {
  margin: 8px;

  @media (max-width: 358px) {
    margin: 0;
  }
}

.ant-card-body {
  @media (max-width: 358px) {
    padding: 8px;
  }
}

.ant-table-wrapper {
  overflow: auto;
}

.ant-picker-calendar-mode-switch {
  display: none;
}

.row {
  display: flex;
  flex-direction: row;

  &.align {
    align-items: center;
  }
}

.no_selection {
  -webkit-user-select: none;
  user-select: none;
}

.full {
  width: 100%;
  text-align: center;
}

.btn-row {
  display: flex;
  justify-content: space-around;

  .green {
    background-color: $green_5;
  }
}

.text-center {
  text-align: center;
}

.ant-btn.viber {
  margin-top: 10px;
  background-color: $purple_5;
}

.two_columns .row {
  float: left;
  width: 40%;
  white-space: nowrap;

  &.full {
    white-space: normal;
  }

  &:nth-child(2n) {
    width: 60%;
  }
}

span.error {
  color: $red_5;
}

span.orange {
  color: $orange_6;
}

span.green {
  color: $green_6;
}

button.orange {
  background-color: $orange_6;
  color: $gray_1;
}

button.blackBtn {
  width: 100%;
  height: auto;
  padding: 8px;
  background-color: #334768;
  border-radius: 20px;
  border-color: $gray_7;
}

.slick-track {
  min-height: 94vh;
}

.ant-btn.logout {
  position: fixed;
  top: 4px;
  right: 8px;
  padding: 0 8px;
  z-index: 10;
  font-size: 18px;

  @media screen and (max-width: 380px) {
    padding: 0 4px;
  }
}

.video_container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}