@import 'common_constants/styles/colors.scss';

@mixin background-gradient {
  background: linear-gradient(225deg, #fff, #e9dcd4 75%, #e4dcd0);
}

@mixin background-gradient-partner {
  background: linear-gradient(145deg, #fff, #c2ccde 75%, #b4c3df);
}

@mixin client-app-page-styles {
  width: 100%;
  padding: 24px 16px 24px;
  max-width: 462px;
  margin: 0 auto;
}

@mixin shadow {
  // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.20), 0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
  box-shadow: -10px 9px 24px rgba(151, 149, 149, 0.59);
}

@mixin border-radius {
  border-radius: 32px;
}

@mixin component-wrapper {
  padding: 32px;
  border-radius: 32px;
  background-color: #fff;
  box-shadow: -2px 2px 5px 3px rgba(0, 0, 0, 0.2);
}

$color-success: #b7eb8f;
$color-success-dark: #52c41a;
$color-info: #91caff;
$color-info-dark: #1890ff;
$color-warning: #fffbe6;
$color-warning-dark: #faad14;
$color-error: #ffccc7;
$color-error-dark: #f5222d;
