@import 'common_constants/styles/colors.scss';
@import '../../mixins';

.alert-notification-custom {
  position: fixed;
  top: 18px;
  right: 16px;
  max-width: 410px;
  border-radius: 12px;
  background-color: $gray_1;
  z-index: 100;

  @include shadow;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.5s, opacity 0.5s;

  @media (max-width: 450px) {
    top: 70px;
    right: 8px;
    left: 8px;
    margin: 0 auto;
    z-index: 9;
  }

  &-body {
    display: flex;
    align-items: center;
    gap: 12px;

    padding: 12px;

    @media (max-width: 450px) {
      gap: 8px;
      padding: 8px;
    }
  }

  &-title {
    font-size: 14px;
    margin: 0 0 4px 0;
  }

  &-text {
    font-size: 12px;
    margin: 0;
    color: $gray_7;
  }

  &-img {
    flex-shrink: 0;
    width: 70px;
    height: 70px;
    border-radius: 8px;
    overflow: hidden;

    img {
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }

  &-btn {
    appearance: none;
    outline: none;
    border: none;
    background-color: transparent;

    a {
      border-radius: 40px;
      max-width: 40px;
      background-color: $blue_6;
      color: $gray_1;
      padding: 8px 12px;
      font-size: 14px;

      transition: background-color 0.3s;
    }
  }

  &-btn:hover {
    a {
      background-color: $blue_5;
    }
  }

  &-icon {
    cursor: pointer;
    padding: 4px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  &-show {
    opacity: 1;
    visibility: initial;
  }
}
