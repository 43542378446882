@import 'common_constants/styles/colors.scss';

.toaster {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: end;
  z-index: 1000;
  row-gap: 10px;
  font-family: 'Roboto', 'Segoe UI', Roboto;

  &.top-right {
    top: 10px;
    right: 20px;
  }
  &.top-center {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  &.top-left {
    top: 10px;
    left: 20px;
  }

  & > .toast-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    position: relative;
    height: fit-content;
    max-width: 400px;
    width: 100%;
    padding: 20px;

    background-color: #fff;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    border: none;

    & > .toast-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 15px;

      & > .toast-header-data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 15px;

        & > .icon {
          display: grid;
          place-content: center;

          padding: 7px;
          height: fit-content;

          font-size: 15px;
          color: white;
          border-radius: 100%;
          border: none;

          &.success {
            background-color: $green_4;
          }
          &.error {
            background-color: $red_5;
          }
          &.warning {
            background-color: $orange_5;
          }
          &.info {
            background-color: $blue_5;
          }
        }
      }

      & > .icon-close {
        font-size: 15px;
        color: $gray_8;
      }
    }
    & > .toast-content {
      display: flex;
      column-gap: 15px;
    }

    &.visible {
      animation: appear 0.5s forwards ease-in-out;
    }

    &.hidden {
      animation: fade 0.5s forwards ease-in-out;
    }

    @keyframes appear {
      0% {
        top: -100%;
        opacity: 0;
      }
      100% {
        top: 10px;
        opacity: 1;
      }
    }

    @keyframes fade {
      0% {
        top: 10px;
        opacity: 1;
      }
      100% {
        top: -100%;
        opacity: 0;
      }
    }
  }
}

// Media query for small screens
@media (max-width: 768px) {
  .toaster {
    row-gap: 5px;

    & > .toast-container {
      row-gap: 10px;
      padding: 10px;

      & > .toast-header {
        column-gap: 10px;

        & > .toast-header-data {
          column-gap: 10px;

          & > .icon {
            padding: 5px;
            font-size: 13px;
          }
        }

        & > .icon-close {
          font-size: 13px;
        }
      }
      & > .toast-content {
        column-gap: 10px;
      }
    }
  }
}
